import React, { useEffect } from "react";
import Aos from "aos";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  return (
    <footer className="footer" data-aos="fade-down">
      <div class="footer-container">
        <div class="address">
          <h3>Location</h3>
          <div class="wrap">
            <p>3DL at the University of Chicago</p>
            <p>John Crerar Library, Room 391</p>
            <p>5730 S. Ellis Avenue</p>
            <p>Chicago, IL 60637, USA.</p>
          </div>
        </div>

        <div class="logo">
          <img src="https://i.imgur.com/nKcNS2Q.png" alt=""></img>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
