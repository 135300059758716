import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Morph from './Morph';
import Seo from './Seo';

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }


const Layout = ({children}) => {
  return (
    <>
      <Seo></Seo>
      <Morph/>
      <Header/>
      <div className="sections-wrapper">
      {children}
      </div>
      <Footer/>
    </>
  )
}

export default Layout;