import React from 'react';

export default function Header() {
  return(
    <>
      <div className="header breakpoint">
          <nav>
          <input id="menu__toggle" type="checkbox" />
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>
          <div class="menu__box">
              <ul>
                  <li><a href="/#about">about</a></li>
                  <li><a href="/#people">people</a></li>
                  <li><a href="/#news">news</a></li>
                  <li><a href="/#research">research</a></li>
                  <li><a href="/#courses">courses</a></li>
                  <li><a href="/#blog">blog</a></li>
              </ul>
              <ul>
                  <li><a href="/join-us">join us</a></li>
              </ul>
              </div>
          </nav>
      </div>
    </>
  )
}
